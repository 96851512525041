import { useQuery } from "@apollo/client";
import RegistrationEditRoleDisciplineModal from "components/Registration/Modals/RegistrationEditRoleDisciplineModal";
import { GET_CAMP_REGISTRATION_REQUIREMENTS } from "graphql/GET_CAMP_REGISTRATION_REQUIREMENTS";
import { GET_CAMP_REGISTRATION_REQUIREMENTS as GET_CAMP_REGISTRATION_REQUIREMENTS_TYPE } from "graphql/types/GET_CAMP_REGISTRATION_REQUIREMENTS";
import {
  useGetUserIsAdmin,
  useGetUserIsCoachSuperAdmin,
} from "modules/common/hooks/useGetCurrentUserType";
import React, { useMemo } from "react";
import { Grid } from "tabler-react";
import { Types } from "../../../../../types/graphql";

type CampRequirementsProps = {
  userRegistrationDetails: Types.UserRegistrationsById;
};

const CampRequirements = ({
  userRegistrationDetails,
}: CampRequirementsProps) => {
  const isAdmin = useGetUserIsAdmin();
  const isCoachSuperAdmin = useGetUserIsCoachSuperAdmin();
  const [isOpen, setIsOpen] = React.useState(false);
  const { data: campRequirementsData } =
    useQuery<GET_CAMP_REGISTRATION_REQUIREMENTS_TYPE>(
      GET_CAMP_REGISTRATION_REQUIREMENTS,
      {
        variables: {
          team_id: userRegistrationDetails.team_id,
          camp_id: Number(userRegistrationDetails.camp_id),
        },
      },
    );

  const campRequirements = useMemo(() => {
    return (
      campRequirementsData?.campRegistrationRequirements
        .filter((r) => r.is_exist)
        .map((r) => r.requirement_type_slug) ?? []
    );
  }, [campRequirementsData?.campRegistrationRequirements]);

  return (
    <Grid.Row className="pb-5">
      {campRequirements.includes("location") && (
        <Grid.Col width="12">
          <b>Location:</b> {userRegistrationDetails.location}
        </Grid.Col>
      )}
      {campRequirements.includes("role") && (
        <Grid.Col width="6">
          <b>Role:</b>{" "}
          <span
            className="cursor-pointer"
            onClick={() => {
              if (isCoachSuperAdmin || isAdmin) {
                setIsOpen(true);
              }
            }}
          >
            {userRegistrationDetails.role ?? <span className="text-muted">Add Role</span>}
          </span>
        </Grid.Col>
      )}
      {campRequirements.includes("discipline") && (
          <Grid.Col width="6">
            <b>Primary Discipline:</b>{" "}
            <span
              className="cursor-pointer"
              onClick={() => {setIsOpen(true)}}
            >
              {userRegistrationDetails.discipline ?? <span className="text-muted">Add Discipline</span>}
            </span>
          </Grid.Col>
        )}
      {(campRequirements.includes("role") ||
        campRequirements.includes("discipline")) && (
        <RegistrationEditRoleDisciplineModal
          isOpen={isOpen}
          campId={Number(userRegistrationDetails.camp_id)}
          toggle={() => setIsOpen(false)}
          registrationId={Number(userRegistrationDetails.user_registrations_id)}
          userRegistrationDetails={userRegistrationDetails}
          campRequirements={campRequirements}
        />
      )}
    </Grid.Row>
  );
};

export default CampRequirements;
