import Modal from 'components/Modal'
import { observer } from 'mobx-react'
import ConfirmEmailMeritInstructionsButton from 'modules/auth-merit/components/buttons/ConfirmEmailMeritInstructionsButton'
import React, { useState, useContext } from 'react'
import { Button } from 'tabler-react'
import { UserStoreContext } from 'contexts/userStoreContext';

const EmailMeritsInstructionsButton = () => {
  const { user } = useContext(UserStoreContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      <Button
        icon="send"
        color="white"
        className="text-muted"
        disabled={!user?.email}
        onClick={toggleModal}
      >
        Email Merit Instructions
      </Button>
      <Modal
        actions={
          <Button.List align="center d-flex">
            <Button color="secondary" onClick={toggleModal}>
              Cancel
            </Button>
            <ConfirmEmailMeritInstructionsButton
              toggleConfirmationModal={toggleModal}
            />
          </Button.List>
        }
        content="Are you sure you want to email USPA/Merit instructions to this user?"
        open={isModalOpen}
        onClose={toggleModal}
      />
    </>
  );
};

export default observer(EmailMeritsInstructionsButton);
