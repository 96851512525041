import React from 'react'
import { EventStoreProvider } from '../../contexts/eventStoreContext'
import EventList from '../../components/Event/EventList'

const EventsHome = () => {
  return (
    <div className="container">
      <EventStoreProvider>
        <EventList />
      </EventStoreProvider>
    </div>
  )
}

export default EventsHome;
