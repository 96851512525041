import PaymentHistory from 'components/User/PaymentHistory'
import React, { useMemo } from 'react'
import { Card, Grid, Table } from 'tabler-react'
import { Types } from '../../../../types/graphql'
import { useGetRegistrationGroups } from '../../../group/hooks/useGetRegistrationGroups'
import GroupPaymentItem from '../../../group/components/GroupPaymentItem'

type RegistrationPaymentsProps = {
  userRegistrationDetails: Types.UserRegistrationsById
  outstanding: number
  totalPaid: number
  hasPaymentsHistory?: boolean
}

const RegistrationPayments: React.FC<RegistrationPaymentsProps> = ({
  userRegistrationDetails,
  outstanding,
  totalPaid,
  hasPaymentsHistory
}: RegistrationPaymentsProps) => {
  const { data: groupsData, loading: groupsLoading } = useGetRegistrationGroups(
    {
      registrationId: userRegistrationDetails.user_registrations_id,
      status: 'Active'
    }
  )

  const groupPayments = useMemo(() => {
    if (groupsData) {
      return groupsData.filter(
        (group) => group.paymentDetails.payments.length > 0
      )
    }
    return []
  }, [groupsData, groupsLoading])
  return (
    <Card>
      <Card.Body>
        <Grid.Row className="">
          <Grid.Col>
            {hasPaymentsHistory && (
              <>
                <div className="h5">Payments</div>
                <hr className="border border-primary my-1" />
                <PaymentHistory
                  studentId={Number(userRegistrationDetails.student_id)}
                  userRegId={userRegistrationDetails.user_registrations_id}
                  remainingBalance={outstanding}
                  totalPaid={totalPaid}
                  currency={userRegistrationDetails.registration_currency}
                />
              </>
            )}
            {groupPayments.length > 0 && (
              <>
                <div className="h5">Group Payments</div>
                <hr className="border border-primary my-1" />
                <Grid.Row>
                  <Grid.Col>
                    <Table>
                      <Table.Header>
                        <Table.Row>
                          <Table.ColHeader>Group/Team</Table.ColHeader>
                          <Table.ColHeader>Amount</Table.ColHeader>
                          <Table.ColHeader>Method</Table.ColHeader>
                          <Table.ColHeader>Created By</Table.ColHeader>
                          <Table.ColHeader>Date</Table.ColHeader>
                          <Table.ColHeader></Table.ColHeader>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {groupPayments.map((group) =>
                          group.paymentDetails.payments.map(
                            (payment: Types.Payments) => (
                              <GroupPaymentItem
                                group={group}
                                payment={payment}
                                key={payment.id}
                              />
                            )
                          )
                        )}
                      </Table.Body>
                    </Table>
                  </Grid.Col>
                </Grid.Row>
              </>
            )}
          </Grid.Col>
        </Grid.Row>
      </Card.Body>
    </Card>
  )
}

export default RegistrationPayments
