import { Button } from 'tabler-react'
import { formatMoney } from 'utils/numberFormat'

export const ButtonPaid = ({
  totalPaid,
  remainingBalance,
  paymentStatus,
  onClick,
  currency
}: {
  totalPaid: number
  remainingBalance: number
  paymentStatus: string
  onClick?: any
  currency: string
}) => {
  return (
    <Button
      icon={paymentStatus === 'Paid' ? 'check-circle' : ''}
      color={paymentStatus === 'Paid' ? 'success' : 'secondary'}
      size="sm"
      onClick={onClick}
    >
      {paymentStatus}: {formatMoney(totalPaid, currency)}
    </Button>
  )
}

export const ButtonOwes = ({
  totalPaid,
  remainingBalance,
  compTotal,
  onClick,
  currency,
  toggleStripeModal
}) => {
  return (
    <>
      {totalPaid > 0 && (
        <Button
          color={remainingBalance !== 0 ? 'secondary' : 'danger'}
          size="sm"
          className="mr-2 mb-1 d-block"
          onClick={onClick}
        >
          Paid:{' '}
          {formatMoney(totalPaid, currency) +
            '/' +
            formatMoney(totalPaid + remainingBalance + compTotal, currency)}
        </Button>
      )}
      <Button
        outline
        icon="alert-circle"
        color={'secondary'}
        size="sm"
        className="mr-2"
        onClick={toggleStripeModal}
      >
        Owes: {formatMoney(remainingBalance, currency)}
      </Button>
    </>
  )
}

export const ButtonComp = ({ compTotal, onClick, currency }) => {
  return (
    <Button
      outline
      icon="check-circle"
      color="secondary"
      size="sm"
      className="mr-2 mb-1 d-block"
      onClick={onClick}
    >
      Comp: {formatMoney(compTotal, currency)}
    </Button>
  )
}

export const PaymentButtons = ({
  totalComp,
  totalPaid,
  outstanding,
  paymentStatus,
  status,
  onClick,
  currency,
  toggleStripeModal
}) => {
  return (
    <>
      {(status === 'Confirmed' || status === 'Accepted') && (
        <>
          {totalComp > 0 && (
            <ButtonComp
              compTotal={totalComp / 100}
              onClick={onClick}
              currency={currency}
            />
          )}
          {outstanding > 0 && (
            <ButtonOwes
              totalPaid={totalPaid / 100}
              remainingBalance={outstanding / 100}
              compTotal={totalComp / 100}
              onClick={onClick}
              toggleStripeModal={toggleStripeModal}
              currency={currency}
            />
          )}
          {outstanding <= 0 && totalPaid !== 0 && (
            <ButtonPaid
              totalPaid={totalPaid / 100}
              remainingBalance={outstanding / 100}
              paymentStatus={paymentStatus}
              onClick={onClick}
              currency={currency}
            />
          )}
        </>
      )}
    </>
  )
}
