import React, { useEffect, useState, useRef } from 'react'
import Modal from '../../Modal'
import QRCode from 'react-qr-code'
import { Button } from 'tabler-react'
import html2canvas from 'html2canvas'

interface IGenerateQRModalProps {
  isOpen: boolean
  handleClose: () => void
  qrValue: string
  setQrValue: React.Dispatch<React.SetStateAction<string>>
}

const GenerateQRModal = ({
  isOpen,
  handleClose,
  qrValue,
  setQrValue
}: IGenerateQRModalProps) => {
  const [value, setValue] = useState('')

  const qrRef = useRef<HTMLDivElement>(null)

  const handleDownload = async () => {
    if (qrRef.current) {
      try {
        const canvas = await html2canvas(qrRef.current)
        const dataUrl = canvas.toDataURL('image/png')

        const link = document.createElement('a')
        link.href = dataUrl
        link.download = `${qrValue}-qrcode.png`
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } catch (error) {
        console.error('Error saving QR Code:', error)
      }
    }
  }

  useEffect(() => {
    setValue('')
  }, [])

  useEffect(() => {
    if (value.length == 0) {
      setQrValue('')
    }
  }, [value])

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      title="Generate QR Code"
      content={
        <div className="d-flex flex-column gap-3 p-4">
          <input
            placeholder="Enter URL or text"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            className='p-2'
          />
          {qrValue && (
            <div
              className="d-flex justify-content-center mt-4 p-3 bg-white"
              ref={qrRef}
            >
              <QRCode value={qrValue} size={200} />
            </div>
          )}
        </div>
      }
      actions={
        <>
          {qrValue && (
            <Button
              icon="download"
              color="secondary"
              onClick={handleDownload}
            >
              Save Image
            </Button>
          )}
          <Button 
            icon="maximize"
            color="primary" 
            onClick={() => setQrValue(value)}
          >
            {qrValue ? 'Update' : 'Generate'}
          </Button>
        </>
      }
    />
  )
}

export default GenerateQRModal
