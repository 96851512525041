import moment from 'moment/moment'
import { Types } from '../../../types/graphql'
import { useQuery } from '@apollo/client'
import { GET_USER_MERITS_BY_USER_ID } from '../../../graphql/GET_USER_MERITS_BY_STUDENT_ID'
import {
  ABCDLicenses,
  EUSPATitles,
  isBurble,
  isLifetimeMemberTemplate,
  isUSPAOrg,
  meritsWithExpiration
} from '../../../components/constants'
import { useContext, useMemo } from 'react'
import { UserStoreContext } from '../../../contexts/userStoreContext'
import { default as useReactRouter } from 'use-react-router'
import { useRootStore } from '../../../hooks'
import { EUserTypes } from '../../../components/User/userTypes'
import { GET_ORG_CREDENTIALS } from '../../../graphql/GET_ORG_CREDENTIALS'
import { GET_TEAM_ORGS } from '../../../graphql/GET_TEAM_ORGS'
import useGetCurrentUserType from '../../common/hooks/useGetCurrentUserType'

export const useGetMeritsCampStatus = ({
  merits,
  start,
  end
}: {
  merits: Types.UserMerits[]
  start: string
  end: string
}) => {
  let uspaStatus = 'Unknown'
  let statusIcon = 'bg-danger'
  let meritsMember = null
  let meritsLicense = null
  if (merits.length > 0) {
    meritsMember = merits.find((uspa) => uspa.merits_uspa_member_id)
    meritsLicense = merits.find((uspa) => uspa.merits_uspa_license)

    if (meritsMember) {
      if (
        moment(end).diff(meritsMember?.merits_uspa_member_expiration, 'days') >
        0
      ) {
        statusIcon = 'bg-warning'
        uspaStatus = 'USPA Active Expiring'
      }

      if (
        moment(end).diff(meritsMember?.merits_uspa_member_expiration, 'days') <
        0
      ) {
        statusIcon = 'bg-success'
        uspaStatus = 'USPA Active'
      }

      if (
        moment().diff(meritsMember?.merits_uspa_member_expiration, 'days') > 0
      ) {
        statusIcon = 'bg-danger'
        uspaStatus = 'USPA Expired'
      }
    }
  }
  return { uspaStatus, statusIcon, meritsMember, meritsLicense }
}

export const useGetMeritsByStudentId = (studentId) => {
  const { data: userMeritsData, loading } = useQuery(GET_USER_MERITS_BY_USER_ID, {
    variables: {
      userId: studentId,
      userType: 'student'
    }
  })

  const hasLifetimeMembership = userMeritsData?.userMerits?.some(
    (item) => isLifetimeMemberTemplate(item)
  )

  const memberLicense = useMemo(() => {
    if (hasLifetimeMembership) {
      return userMeritsData?.userMerits.find(
        (item) => item.template_title === 'Lifetime Member'
      )
    }

    return userMeritsData?.userMerits.find(
      (item) => item.template_title === EUSPATitles.Member && item.merits_uspa_member_id
    )
  }, [hasLifetimeMembership, userMeritsData?.userMerits])

  const sortedABCDLicenses = useMemo(
    () =>
      userMeritsData?.userMerits
        .filter((item) => ABCDLicenses.includes(item.template_title))
        .sort((a, b) => a.template_title.localeCompare(b.template_title)) ?? [],
    [userMeritsData?.userMerits] // Eslint-disable-line
  )

  return { memberLicense, hasLifetimeMembership, sortedABCDLicenses, loading }
}

export const useGetUserMerits = () => {
  const { user } = useContext(UserStoreContext)
  const { location } = useReactRouter()
  const { currentUser } = useRootStore()
  const isPersonal = location.pathname === '/user/profile'
  const param = {
    userId: isPersonal ? currentUser?.id.toString() : user?.id,
    userType: isPersonal ? currentUser.type : EUserTypes.student
  }

  const { data: meritsData, loading: meritsLoading } = useQuery(
    GET_USER_MERITS_BY_USER_ID,
    {
      variables: {
        ...param
      }
    }
  )

  const merits = meritsData?.userMerits ?? []

  const coachLicense = useMemo(
    () =>
      merits.find(
        (item): boolean => item.template_title === EUSPATitles.CoachRating
      ),
    [merits] // Eslint-disable-line
  )

  const otherMeritsWithExpiration = useMemo(
    () =>
      merits
        .filter((item) => meritsWithExpiration.includes(item.template_title))
        .sort((a, b) => a.template_title.localeCompare(b.template_title)) ?? [],
    [merits] // Eslint-disable-line
  )

  const isCoachLicenseExpired = useMemo(
    () =>
      coachLicense?.merits_uspa_member_expiration &&
      new Date(coachLicense.merits_uspa_member_expiration) < new Date(),
    [coachLicense]
  )

  const memberName = useMemo(
    () =>
      merits[0]?.first_name && merits[0]?.last_name
        ? `${merits[0].first_name} ${merits[0].last_name}`
        : null,
    [merits] // Eslint-disable-line
  )

  const memberEmail = useMemo(
    () => merits[0]?.email ?? null,
    [merits] // Eslint-disable-line
  )

  const memberUpdated = useMemo(
    () =>
      merits[0]?.updated_on
        ? new Date(merits[0]?.updated_on).toLocaleString()
        : null,
    [merits] // Eslint-disable-line
  )

  const hasLifetimeMembership = merits.some((item) => {
    isLifetimeMemberTemplate(item)
  })

  const memberLicense = useMemo(() => {
    if (hasLifetimeMembership) {
      return merits.find(
        (item) => isLifetimeMemberTemplate(item) && isUSPAOrg(item)
      )
    }

    return merits.find(
      (item) => item.template_title === EUSPATitles.Member && isUSPAOrg(item)
    )
  }, [hasLifetimeMembership, merits]) // Eslint-disable-line

  const isMemberLicenseExpired = useMemo(
    () =>
      memberLicense?.merits_uspa_member_expiration &&
      new Date(memberLicense.merits_uspa_member_expiration) < new Date(),
    [memberLicense]
  )

  const sortedABCDLicenses = useMemo(
    () =>
      merits
        .filter((item) => ABCDLicenses.includes(item.template_title))
        .sort((a, b) => a.template_title.localeCompare(b.template_title)) ?? [],
    [merits] // Eslint-disable-line
  )

  // Sort the array of licenses
  const sortedOtherLicenses =
    merits
      .filter(
        (item) =>
          !isLifetimeMemberTemplate(item) &&
          ![
            ...ABCDLicenses,
            ...meritsWithExpiration,
            EUSPATitles.CoachRating,
            EUSPATitles.Member
          ].includes(item.template_title)
      )
      .sort((a, b) => a.template_title.localeCompare(b.template_title)) ?? []

  //get blurbs from merits
  const burble = useMemo(() => {
    return merits.filter((item) => isBurble(item)) || []
  }, [merits])

  return {
    memberLicense,
    isMemberLicenseExpired,
    sortedABCDLicenses,
    sortedOtherLicenses,
    memberUpdated,
    memberEmail,
    memberName,
    isCoachLicenseExpired,
    otherMeritsWithExpiration,
    coachLicense,
    hasLifetimeMembership,
    meritsLoading,
    merits,
    burble
  }
}

export const useGetStudentOrgCredentials = (studentId) => {
  const { isAdmin, isStudent } = useGetCurrentUserType()
  const { currentCoachTeam } = useRootStore()
  const orgFilter = !isAdmin && !isStudent ? currentCoachTeam.id : null
  const { data: studentManualCredentials, loading: manualCredentialsLoading } =
    useQuery(GET_ORG_CREDENTIALS, {
      variables: { studentId: studentId }
    })

  const { data: orgData, loading: orgLoading } = useQuery(GET_TEAM_ORGS, {
    variables: { teamId: orgFilter }
  })

  const credentialsLoading = manualCredentialsLoading || orgLoading

  return {
    studentManualCredentials: studentManualCredentials?.orgCredentials,
    teamOrgs: orgData?.teamOrgs,
    credentialsLoading
  }
}
