import { useMutation } from '@apollo/client'
import { Formik } from 'formik'
import moment from 'moment'
import React, { useMemo } from 'react'
import { toast } from 'react-toastify'
import { Button, Form, Grid, Icon } from 'tabler-react'
import * as Yup from 'yup'

import { ADD_STUDENT_ORG_CREDENTIAL } from '../../../graphql/ADD_STUDENT_ORG_CREDENTIAL'
import { EDIT_STUDENT_ORG_CREDENTIAL } from '../../../graphql/EDIT_STUDENT_ORG_CREDENTIAL'
import { useRootStore } from '../../../hooks'
import useGetCurrentUserType from '../../../modules/common/hooks/useGetCurrentUserType'

import { Types } from '../../../types/graphql'
import { BASE_ORG_ID } from '../../constants'
import MemberCredentialImageUploadModal from '../Modal/MemberCredentialImageUploadModal'
import ManualAdLicenseForm from './ManualADLicenseForm'
import ManualRatingForm from './ManualRatingForm'

const manualCredentialsValidationSchema = Yup.object().shape({
  membership: Yup.string().required('Member number is required'),
  expires: Yup.date()
    .nullable() // Allows the value to be null
    .typeError('Invalid date format') // Error message for invalid date
})

interface ManualCredentialFormProps {
  editable: boolean
  orgCredentials: Types.OrgCredential[]
  orgId: number
  studentId: number
}

const ManualCredentialForm = ({
  orgId,
  studentId,
  orgCredentials,
  editable
}: ManualCredentialFormProps) => {
  const { isAdmin, isStudent } = useGetCurrentUserType()
  const { currentUser } = useRootStore()
  const [showAdd, setShowAdd] = React.useState(false)

  const [addStudentOrgCredential] = useMutation(ADD_STUDENT_ORG_CREDENTIAL, {
    refetchQueries: ['GET_ORG_CREDENTIALS'],
    onCompleted: () => {
      toast.success('Credential added successfully')
    }
  })

  const [updateStudentOrgCredential] = useMutation(
    EDIT_STUDENT_ORG_CREDENTIAL,
    {
      refetchQueries: ['GET_ORG_CREDENTIALS'],
      onCompleted: () => {
        toast.success('Credential updated successfully')
      }
    }
  )

  const orgCredentialMembership = useMemo(
    () => orgCredentials.find((oc) => oc.membership),
    [orgCredentials]
  )

  const orgCredentialRating = useMemo(
    () =>
      orgCredentials
        .filter((oc) => oc.rating)
        .sort((a, b) => a.rating.localeCompare(b.rating)),
    [orgCredentials]
  )

  const orgCredentialADLicense = useMemo(
    () =>
      orgCredentials
        .filter((oc) => oc.license || oc.nac_id)
        .sort((a, b) => a.license.localeCompare(b.license)),
    [orgCredentials]
  )

  const [isOpen, setIsOpen] = React.useState(false)
  const isEditable = isAdmin || (isStudent && studentId === currentUser.id)

  const renderForm = ({
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    dirty,
    errors,
    touched
  }) => (
    <>
      <MemberCredentialImageUploadModal
        isOpen={isOpen}
        toggle={() => setIsOpen(false)}
        membership={orgCredentialMembership}
      />
      {orgId !== BASE_ORG_ID && (
        <>
          <Grid.Row className="mt-5">
            <Grid.Col xs={12} sm={12} md={6} lg={6}>
              <Form.Group label="Member #">
                <Form.InputGroup>
                  <Form.Input
                    autoFocus={true}
                    name="membership"
                    placeholder="Enter member number"
                    value={values.membership}
                    onChange={handleChange}
                    disabled={isEditable && values.verified_on}
                  />
                  {values.membership && values.id && (
                    <>
                      <Form.InputGroupAppend>
                        <Button
                          icon={
                            values.verified_on ? 'check-circle' : 'alert-circle'
                          }
                          color="white"
                          className={
                            values.verified_on ? 'text-success' : 'text-danger'
                          }
                          disabled={values.verified_on || isStudent}
                          onClick={() => {
                            const membershipClone = { ...values }
                            membershipClone.verified_by =
                              currentUser.id.toString()
                            membershipClone.verified_on =
                              moment().format('YYYY-MM-DD')
                            updateStudentOrgCredential({
                              variables: {
                                updateOrgCredentialInput: membershipClone
                              }
                            })
                          }}
                        >
                          {values.verified_on
                            ? 'Verified'
                            : !values.verified_on && isStudent
                            ? 'Unverified'
                            : 'Verify'}
                        </Button>
                        <Button
                          icon="image"
                          color="white"
                          className={` ${
                            orgCredentialMembership?.image
                              ? 'text-success'
                              : 'text-default'
                          }`}
                          onClick={() => setIsOpen(true)}
                          //  disabled={!isAdmin}
                        />
                      </Form.InputGroupAppend>
                    </>
                  )}
                </Form.InputGroup>
                <span className="field-error text-danger">
                  {errors.membership && touched.membership && errors.membership}
                </span>
              </Form.Group>
            </Grid.Col>
            <Grid.Col xs={12} sm={12} md={6} lg={6}>
              <Form.Group label={orgId !== 1 && 'Expires'}>
                <Form.InputGroup>
                  {orgId !== 1 && (
                    <Form.Input
                      valid={moment().isBefore(values.expires)}
                      tick={moment().isBefore(values.expires)}
                      invalid={moment().isAfter(values.expires)}
                      cross={moment().isAfter(values.expires)}
                      type="date"
                      name="expires"
                      value={values.expires}
                      onChange={handleChange}
                      disabled={isEditable && values.verified_on}
                      className={`${values.lifetime && orgId === 1 && 'd-none'}`}
                    />
                  )}
                  {isAdmin && orgId === 1 && (
                    <Form.InputGroupAppend>
                      <Icon
                        name={"star"}
                        color={"white"}
                        className={`${values.lifetime && 'text-warning'} mt-auto mb-auto mx-2`}
                        onClick={() => {
                          if(isEditable && values.verified_on) {
                            setFieldValue('lifetime', values.lifetime === 1 ? 0 : 1)
                          }
                        }
                        }
                      />
                      <span className={'my-auto'}>{!!values.lifetime && 'Lifetime Member'}</span>
                    </Form.InputGroupAppend>
                  )}
                  {values.id && editable && dirty && (
                    <Form.InputGroupAppend>
                      <Button
                        color="primary"
                        className="ml-2"
                        onClick={handleSubmit}
                        disabled={''}
                      >
                        UPDATE
                      </Button>
                    </Form.InputGroupAppend>
                  )}
                  {!values.id && dirty && (
                    <Form.InputGroupAppend>
                      <Button
                        color="primary"
                        className="ml-2"
                        onClick={handleSubmit}
                        disabled={''}
                      >
                        SAVE
                      </Button>
                    </Form.InputGroupAppend>
                  )}
                </Form.InputGroup>
                <span className="field-error text-danger">
                  {errors.expires && touched.expires && errors.expires}
                </span>
              </Form.Group>
            </Grid.Col>
          </Grid.Row>
        </>
      )}

      {(values.id || orgId === BASE_ORG_ID) && (
        <>
          {isAdmin && (
            <>
              {orgCredentialRating &&
                orgCredentialRating.map((ocRating) => (
                  <ManualRatingForm rating={ocRating} key={ocRating.id} />
                ))}
              {!showAdd && (
                <Grid.Row>
                  <Grid.Col xs={12} sm={12} md={6} lg={6}>
                    <Button.List align="left">
                      <Button
                        outline
                        color="primary"
                        onClick={() => setShowAdd(true)}
                        icon={'award'}
                      >
                        ADD RATING
                      </Button>
                    </Button.List>
                  </Grid.Col>
                </Grid.Row>
              )}
              {showAdd && (
                <ManualRatingForm
                  rating={{
                    rating: '',
                    expire: '',
                    student_id: values.student_id,
                    org_id: values.org_id
                  }}
                />
              )}
            </>
          )}
          {orgId !== BASE_ORG_ID && (
            <>
              <Grid.Row className="mt-5">
                <Grid.Col xs={12} sm={12} md={6} lg={6}>
                  <Form.Group className="mb-0" />
                </Grid.Col>
                <Grid.Col xs={12} sm={12} md={6} lg={6}>
                  <Form.Group label="License Number" className="mb-0" />
                </Grid.Col>
              </Grid.Row>
              {orgCredentialADLicense &&
                orgCredentialADLicense.map((ocADL) => (
                  <ManualAdLicenseForm
                    license={ocADL}
                    key={ocADL.id}
                    licenses={orgCredentialADLicense}
                  />
                ))}
              <ManualAdLicenseForm
                license={{
                  license: '',
                  license_number: '',
                  student_id: values.student_id,
                  org_id: values.org_id
                }}
                licenses={orgCredentialADLicense}
              />
            </>
          )}
        </>
      )}
    </>
  )

  return (
    <Formik
      validationSchema={manualCredentialsValidationSchema}
      enableReinitialize={true}
      initialValues={{
        id: orgCredentialMembership?.id || undefined,
        org_id: orgId,
        student_id: studentId,
        membership: orgCredentialMembership?.membership || '',
        expires: orgCredentialMembership?.expires || '',
        verified_on: orgCredentialMembership?.verified_on || null,
        verified_by: orgCredentialMembership?.verified_by || null,
        lifetime: !orgCredentialMembership?.expires && orgId === 1 ? 1 : 0
      }}
      onSubmit={async (values, { setSubmitting, setFieldError }) => {
        const { lifetime, ...rest } = values
        if (lifetime === 1 && rest.org_id === 1) {
          rest.expires = null
        } else {
          if (rest.expires === null || rest.expires === '') {
            setFieldError('expires', 'Expiry date is required')
            setSubmitting(false)
            return false
          }
          rest.expires = moment(rest.expires).format('YYYY-MM-DD')
        }

        if (rest.id) {
          if (isStudent) {
            rest.verified_by = null
            rest.verified_on = null
          }

          await updateStudentOrgCredential({
            variables: {
              updateOrgCredentialInput: rest
            }
          })
        } else {
          if (!isStudent) {
            rest.verified_by = currentUser.id.toString()
            rest.verified_on = moment().format('YYYY-MM-DD')
          }

          if (rest.membership) {
            await addStudentOrgCredential({
              variables: {
                addOrgCredentialInput: rest
              }
            })
          }
        }
        setSubmitting(false)
      }}
    >
      {renderForm}
    </Formik>
  )
}

export default ManualCredentialForm
