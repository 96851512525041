import gql from 'graphql-tag'

export const GET_CAMP_STUDENTS = gql`
  query GET_CAMP_STUDENTS(
    $campId: ID
    $filter: String
    $limit: Int
    $order: String
  ) {
    getCampRegisteredStudents(
      campId: $campId
      filter: $filter
      limit: $limit
      order: $order
    ) {
      id
      student_id
      address1
      address2
      auto_approval
      camp_description
      camp_email
      camp_fees
      camp_id
      camp_name
      camp_name
      camp_phone
      camp_url
      campsJoined
      registration_currency
      sanction_fee
      sanction_fee_currency
      city
      country
      created_on
      default_jump_price
      discipline
      dob
      email
      end
      event_type_name
      first_name
      gender
      groupName
      height
      is_public
      jump_quantity
      jump_weight
      last_name
      location
      location_id
      nac
      registrationLocation
      reach
      phone_country_code
      phone_number
      postal_code
      profile_avatar
      profile_picture
      province
      regId
      registration_code
      registration_id
      rating
      role
      shirt_size_id
      shirt_size
      slot_duration
      stance
      start
      status
      status_color
      status_icon
      team_id
      user_registrations_id
      weight

      paymentDetail {
        registrationOptions {
          id
          registration_option_id
          name
          option_value
          quantity
          fee_type
        }
        total
        outstanding
        totalPaid
        totalFees
        totalComp
        compCount
        paidCount
      }

      totalTime {
        total_duration
      }

      uspa {
        isLicenseExpired
        isLicenseExpiring
        uspaStatus
        memberLicense {
          id
          user_id
          user_type
          template_id
          template_title
          email
          first_name
          last_name
          updated_on
          merits_uspa_member_id
          merits_uspa_member_expiration
          merits_org_id
          merits_id
          merits_uspa_license
        }
        userMerit {
          id
          user_id
          user_type
          template_id
          template_title
          email
          first_name
          last_name
          updated_on
          merits_uspa_member_id
          merits_uspa_member_expiration
          merits_org_id
          merits_id
          merits_uspa_license
        }
      }

      orgCredentials {
        id
        org_id
        org_name
        org_slug
        student_id
        membership
        expires
        license
        license_number
        rating
        verified_on
        verified_by
        created_on
        image
        nac_id
        nac_country
        nac_slug
        nac_name
      }
    }
  }
`
