import { clone } from 'mobx-state-tree';
import React, { useState } from 'react';
import { Icon, Table, Tag, Text } from 'tabler-react';

import UpdateEvent from './Forms/UpdateEvent';
import { useRootStore } from '../../hooks';
import { EUserTypes } from 'components/User/userTypes';
import CalendarLink from '../Calendar/CalendarLink';
import { Types } from '../../types/graphql'

interface IEventListItemProps {
  event: Types.Camp;
}

const EventListItem = ({ event }) => {
  const rootStore = useRootStore();
  const { currentUser } = rootStore;
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [eventClone, setEventClone] = useState(event);

  const onToggleEdit = () => {
    setIsEditing(true);
    setEventClone(clone(event));
  };

  return isEditing ? (
    <UpdateEvent
      event={event}
      eventClone={eventClone}
      setIsEditing={setIsEditing}
    />
  ) : (
    <>
    {currentUser.type === EUserTypes.admin && (
      <Table.Col>
        <Text RootComponent="span" muted>
          {event.id}
        </Text>
      </Table.Col>
    )}
      <Table.Col>
        <React.Fragment>
          {event.is_published ? (
            <Text className="mr-1">
              <Icon name="check-circle" className="text-success" />
            </Text>
          ) : (
            <Text className="mr-1">
              <Icon name="lock" className="text-gray-dark" />
            </Text>
          )}
        </React.Fragment>
        <React.Fragment>
          {event.is_public ? (
            <Text className="mr-1">
              <Icon name="calendar" className="text-primary" />
            </Text>
          ) : (
            <Text className="mr-1">
              <Icon name="calendar" className="text-muted" />
            </Text>
          )}
        </React.Fragment>
      </Table.Col>
      <Table.Col>
        <CalendarLink eventName={event.camp_name}
                      eventId={Number(event.id)} />
                      <span className='small text-muted d-block'>{new Date(event.start).toLocaleDateString()}</span>
      </Table.Col>
      <Table.Col><Icon name="map-pin" className="text-muted mr-2" />{event.location}</Table.Col>
      <Table.Col>
        <Tag className="ml-2">{event.event_type_name}</Tag>
        {currentUser.type === EUserTypes.admin && (
          <Icon link name="edit" onClick={onToggleEdit} />
        )}
      </Table.Col>
    </>
  );
};

export default EventListItem;
