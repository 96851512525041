import { IconBrandX, IconBrandTiktok, IconBrandYoutube } from '@tabler/icons-react'
import { EHostnames } from 'components/constants'
import { useRootStore } from 'hooks'
import { Container, Grid, Icon, List, Tag, Text } from 'tabler-react'
import { getEnvironmentFromHostname } from 'utils/misc'

const FooterBottom = () => {
  const date = new Date()
  const year = date.getFullYear()

  const { domainData } = useRootStore()
  // const nodeEnv = useGetNodeEnvironment()
  const nodeEnv = getEnvironmentFromHostname(window.location.hostname)

  return (
    <>
      <footer className="footer">
        <Container>
          <Grid.Row className="align-items-center">
            <Grid.Col>
              <List inline className="list-inline-dots mb-0">
                <List.Item inline>
                  © {year + ' '}
                  <a
                    href={
                      domainData?.key === 'skycru'
                        ? 'https://skycru.com'
                        : 'https://fairfights.ca'
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {domainData?.name ?? EHostnames.SKYCRU}
                  </a>
                </List.Item>
                {domainData?.key === EHostnames.FAIRFIGHTS ? (
                  <>
                    <List.Item inline>
                      <a
                        href="/help"
                        className="text-muted"
                      >
                        HELP
                      </a>
                    </List.Item>
                    <List.Item inline>
                      <a
                        href="https://fairfights.ca/privacy"
                        className="text-muted"
                        target="_blank"
                      >
                        PRIVACY
                      </a>
                    </List.Item>
                  </>
                ) : (
                  <>
                    <List.Item inline>
                      <a href="/help" className="text-muted">
                        HELP
                      </a>
                    </List.Item>
                    <List.Item inline>
                      <a href="https://skycru.com/privacy" className="text-muted" target="_blank">
                        PRIVACY
                      </a>
                    </List.Item>
                  </>
                )}
              </List>
              {domainData?.key === EHostnames.SKYCRU && (
                  <>
                    <a
                      href="https://www.facebook.com/skycru/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Icon name="facebook" className="btn btn-md" />
                    </a>
                    <a
                      href="https://x.com/skycru/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fe btn btn-md">
                        <IconBrandX size={14} />
                      </i>
                    </a>
                    <a
                      href="https://www.linkedin.com/company/skycru"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Icon name="linkedin" className="btn btn-md" />
                    </a>
                    {/*  HIDE TEMPORARILY
                  <a
                    href="https://www.instagram.com/fly.skycru/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icon name="instagram" className="btn btn-md" />
                  </a
                  */}
                  </>
                )}
              {domainData?.key === EHostnames.FAIRFIGHTS && (
                <>
                  <a
                    href="https://www.facebook.com/fairfights/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icon name="facebook" className="btn btn-md" />
                  </a>
                  <a
                    href="https://x.com/fairfights_/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fe btn btn-md">
                      <IconBrandX size={14} />
                    </i>
                  </a>
                  <a
                    href="https://www.linkedin.com/company/fairfights"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icon name="linkedin" className="btn btn-md" />
                  </a>
                  <a
                    href="https://www.instagram.com/fairfights.ca/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icon name="instagram" className="btn btn-md" />
                  </a>
                  <a
                    href="https://www.youtube.com/@fairfights"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fe btn btn-md">
                      <IconBrandYoutube size={14} />
                    </i>
                  </a>
                  <a
                    href="https://www.tiktok.com/@fairfights.ca"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fe btn btn-md">
                      <IconBrandTiktok size={14} />
                    </i>
                  </a>
                </>
              )}
            </Grid.Col>
            <Grid.Col auto={true} className="ml-auto">
              <Grid.Row className="align-items-center">
                <Grid.Col className="col-auto">
                  <List inline className="list-inline-dots mb-0">
                    <List.Item inline>
                      {domainData?.siteTag &&
                        process.env.NODE_ENV === 'production' && (
                          <Tag color="primary" size="sm">
                            {domainData?.siteTag}
                          </Tag>
                        )}
                      {process.env.NODE_ENV !== 'production' && (
                        <Tag color="white" className={'text-danger'} size="sm">
                          {nodeEnv}
                        </Tag>
                      )}
                    </List.Item>
                    <List.Item inline>
                      <Text.Small className="text-muted">v2.14.122</Text.Small>
                    </List.Item>
                  </List>
                </Grid.Col>
                {/*
                <Grid.Col>
                  <a
                    className="btn btn-sm btn-outline-primary"
                    href="https://apps.apple.com/us/app/skycru/id1292785676"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icon prefix="fa" name="apple" aria-hidden="true" /> iOS
                  </a>
                </Grid.Col>
                <Grid.Col className="col-auto">
                  <a
                    className="btn btn-sm btn-outline-primary"
                    href="https://play.google.com/store/apps/details?id=com.skycru.app"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icon prefix="fa" name="android" aria-hidden="true" /> Android
                  </a>
                </Grid.Col>
                */}
              </Grid.Row>
            </Grid.Col>
          </Grid.Row>
        </Container>
      </footer>
    </>
  )
}

export default FooterBottom
