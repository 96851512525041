import { Link } from 'react-router-dom';
import { Grid, StampCard } from 'tabler-react';
import { useRootStore } from '../../hooks/useRootStore';
import formatNumber from '../../utils/numberFormat';
import { observer } from 'mobx-react';

const StudentsTotal = () => {
  const rootStore = useRootStore();
  const { studentsTotal, registeredStudentsTotal } = rootStore;

  return (
    <Grid.Col sm={6} lg={3} xs={12} className="mb-0">
      <Link to="/admin/user-list" className='cursor-pointer text-gray-dark'>
        <StampCard
          color="primary"
          icon="users"
          header={
            <>
              {formatNumber(registeredStudentsTotal)} <small>Active</small>
              <small className='ml-2 text-success'>{((registeredStudentsTotal / studentsTotal) * 100).toFixed(2)}%</small>
            </>
          }
          footer={`${formatNumber(studentsTotal)} users `}
        />
      </Link>
    </Grid.Col>
  );
};

export default observer(StudentsTotal);
