import FreshChatWidget from '../../widgets/FreshChatWidget'
import { UserStoreProvider } from '../../contexts/userStoreContext'
import { observer } from 'mobx-react'
import StudentSignup from '../../components/Coaches/StudentSignup'

const StudentSignupForm = (props: any) => {
  return (
    <>
      <div className="page">
        <div className="page-single">
          <div className="container">
            <div className="row">
              <UserStoreProvider>
                <StudentSignup {...props} />
              </UserStoreProvider>
            </div>
          </div>
        </div>
      </div>
      <FreshChatWidget />
    </>
  )
}

export default observer(StudentSignupForm)
