import React, { useState } from "react";
import { Container, Icon } from "tabler-react";
import StudentSearchForm from "../../components/Students/Forms/StudentSearchForm";

const MobileSearch = ({ collapse, setCollapse }) => {
  return (
    <>
      <div className={"float-left"}>
        <StudentSearchForm />
      </div>
      <a
        className="nav-link active d-inline-block float-left"
        href="#"
        onClick={() => setCollapse(!collapse)}
      >
        <Icon name={"x"} />
      </a>
    </>
  );
};

const AdminMobileNav = () => {
  const [collapse, setCollapse] = useState(true);

  return (
    <>
      <div className={`collapse-horizontal ${!collapse && "collapse"}`}>
        <Container>
          <nav className="navbar fixed-bottom navbar-light bg-light text-center pt-4 pb-5">
            <>
              <li className="nav-item">
                <a className="active d-inline-block" href="/">
                  <Icon className="d-block" name={"home"} />
                  <span className="d-block small">Home</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="active d-inline-block" href="/payments">
                  <Icon className="d-block" name={"credit-card"} />
                  <span className="d-block small">Payments</span>
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="active d-inline-block"
                  href="/admin/transaction-list"
                >
                  <Icon className="d-block" name={"list"} />
                  <span className="d-block small">Transactions</span>
                </a>
              </li>
            </>
            <li className="nav-item">
              <a
                className="active d-inline-block"
                onClick={() => setCollapse(false)}
              >
                <Icon className="d-block" name={"search"} />
                <span className="d-block small">Search</span>
              </a>
            </li>
          </nav>
        </Container>
      </div>
      <div className={`${collapse && "collapse"} collapse-horizontal`}>
        <Container>
          <nav className="navbar fixed-bottom navbar-light bg-light text-center pt-4 pb-5">
            <li className="nav-item">
              <MobileSearch collapse={collapse} setCollapse={setCollapse} />
            </li>
          </nav>
        </Container>
      </div>
    </>
  );
};

export default AdminMobileNav;
