import gql from 'graphql-tag';

export const GET_USER_MERITS_BY_USER_ID = gql`
  query GET_USER_MERITS_BY_USER_ID($userId: String!, $userType: UserType!) {
    userMerits(userId: $userId, userType: $userType) {
      id
      template_id
      template_title
      email
      first_name
      last_name
      updated_on
      merits_uspa_member_id
      merits_uspa_member_expiration
      merits_org_id
      merits_id
      merits_uspa_license
    }
  }
`;
