import { useQuery } from '@apollo/client'
import { GET_CAMP_STUDENTS as GET_CAMP_STUDENTS_TYPE } from 'graphql/types/GET_CAMP_STUDENTS'
import { GET_CAMP_STUDENTS } from '../../../../graphql/GET_CAMP_STUDENTS'
import { GET_REGISTERED_STUDENTS } from '../../../../graphql/GET_REGISTERED_STUDENTS'
import { GET_REGISTERED_STUDENTS as GET_REGISTERED_STUDENTS_TYPE } from 'graphql/types/GET_REGISTERED_STUDENTS'

const useGetRegisteredStudents = (params: { campId: string }) => {
  const { campId } = params

  const { loading, data } = useQuery<GET_REGISTERED_STUDENTS_TYPE>(
    GET_REGISTERED_STUDENTS,
    {
      variables: {
        campId: Number(campId)
      },
      fetchPolicy: 'network-only'
    }
  )

  return { loading, data: data?.getRegisteredStudents || [] }
}

export const useGetCampStudents = (params: { campId: string }) => {
  const { campId } = params

  const { loading, data } = useQuery<GET_CAMP_STUDENTS_TYPE>(
    GET_CAMP_STUDENTS,
    {
      variables: {
        campId: Number(campId)
      },
      fetchPolicy: 'network-only'
    }
  )

  return { loading, data: data?.getCampRegisteredStudents || [] }
}

export default useGetRegisteredStudents
