import { useMemo } from 'react'

export const useGetGroupsByDiscipline = (groups) => {
  const disciplines = useMemo(() => {
    if (groups) {
      return groups
        .map((group) => ({
          value: group.discipline_id,
          label: group.discipline
        }))
        .filter(
          (item, index, self) =>
            index === self.findIndex((obj) => obj.value === item.value)
        )
    }
    return []
  }, [groups])

  const groupsByDiscipline = useMemo(() => {
    if (groups) {
      return groups.reduce((acc, curr) => {
        if (!acc[curr.discipline_id]) {
          acc[curr.discipline_id] = []
        }
        acc[curr.discipline_id].push(curr)
        return acc
      }, {})
    }
    return {}
  }, [groups])

  return { disciplines, groupsByDiscipline }
}
