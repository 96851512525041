import { useMutation } from '@apollo/client'
import axios from 'axios'
import { isUSPAOrg } from 'components/constants'
import { UserStoreContext } from 'contexts/userStoreContext'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import Select from 'react-select'
import { Button, Card, Dimmer, Form, Grid, Icon, Text } from 'tabler-react'
import { GET_ORG_CREDENTIALS } from '../../../graphql/GET_ORG_CREDENTIALS'
import { useRootStore } from '../../../hooks'
import useGetCurrentUserType from '../../../modules/common/hooks/useGetCurrentUserType'
import { Types } from '../../../types/graphql'
import ManualCredentialForm from '../../Students/Forms/ManualCredentialForm'
import { REMOVE_ALL_ORG_CREDENTIALS } from '../../../graphql/REMOVE_ALL_ORG_CREDENTIALS'

const useDataFetch = (url: string) => {
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)
  const [isLoading, setLoading] = useState(false)

  const fetchData = async () => {
    setLoading(true)
    try {
      const response = await axios.get(url)
      setData(response.data)
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [url])

  const refetch = () => {
    fetchData()
  }

  return { data, error, isLoading, refetch }
}

const UserCredentialsForm = ({ params }) => {
  const innerParams = {
    meritsIsLoading: params.meritsLoading,
    ...params
  }
  return <UserCredentialsFormInner {...innerParams} />
}

const UserCredentialsFormInner = ({
  meritsIsLoading,
  merits,
  hasLifetimeMembership,
  coachLicense,
  otherMeritsWithExpiration,
  isCoachLicenseExpired,
  memberName,
  memberEmail,
  memberUpdated,
  memberLicense,
  isMemberLicenseExpired,
  sortedABCDLicenses,
  sortedOtherLicenses,
  credentialsLoading,
  studentManualCredentials,
  orgs
}) => {
  const { currentUser } = useRootStore()
  const { user } = useContext(UserStoreContext)
  const { isAdmin, isStudent } = useGetCurrentUserType()

  const [selectedOrg, setSelectedOrg] = useState<number>(null)
  const [selectedOrgCredentials, setSelectedOrgCredentials] = useState([])

  useEffect(() => {
    if (studentManualCredentials) {
      if (selectedOrg && !!studentManualCredentials) {
        setSelectedOrgCredentials(
          studentManualCredentials.filter(
            (oc) => Number(oc.org_id) === selectedOrg
          )
        )
      } else {
        setSelectedOrgCredentials([])
      }
    }
  }, [studentManualCredentials, credentialsLoading, selectedOrg])

  const handleOrgChange = (orgId: number) => {
    setSelectedOrg(orgId)
  }

  const [removeAllCredentials] = useMutation(REMOVE_ALL_ORG_CREDENTIALS, {
    refetchQueries: [
      {
        query: GET_ORG_CREDENTIALS,
        variables: { studentId: isStudent ? currentUser?.id : user?.id }
      }
    ]
  })

  const handleRemoveCredentialClick = () => {
    if (window.confirm('Are you sure you want to remove these credentials?')) {
      removeAllCredentials({
        variables: {
          studentId: isStudent ? currentUser?.id : user?.id,
          orgId: selectedOrg
        }
      })
    }
  }

  const canEdit = useMemo(() => {
    if (isAdmin) {
      return true
    }

    if (isStudent) {
      return true
    }

    const currentOrg = orgs.find(
      (org: Types.TeamOrg) => Number(org.org_id) === selectedOrg
    )

    return currentOrg?.exist
  }, [orgs, selectedOrg, isAdmin, isStudent])

  const customFilter = (option, searchText) => {
    return (
      option.data.name.toLowerCase().includes(searchText.toLowerCase()) ||
      option.data.slug.toLowerCase().includes(searchText.toLowerCase())
    )
  }
  const loading = credentialsLoading || meritsIsLoading

  return (
    <>
      <Card.Body>
        <Dimmer active={loading} loader={loading}>
          {orgs.length > 0 && (
            <Grid.Row>
              <Grid.Col sm={6} md={6}>
                <Form.Group label="Organization">
                  <Select
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 })
                    }}
                    menuPortalTarget={document.body}
                    isDisabled={orgs.length === 0}
                    name="organization"
                    getOptionLabel={(option: Types.TeamOrg) => (
                      <>
                        <span className="mr-1 font-weight-bold">
                          {option.slug.toUpperCase()}
                        </span>
                        {option.name}
                      </>
                    )}
                    getOptionValue={(option: Types.TeamOrg) => option.org_id}
                    filterOption={customFilter}
                    options={orgs}
                    onChange={(e) => {
                      handleOrgChange(Number(e.org_id))
                    }}
                    value={
                      orgs.find(
                        (org: Types.TeamOrg) =>
                          Number(org.org_id) === selectedOrg
                      ) || orgs[0]
                    }
                  />
                </Form.Group>
              </Grid.Col>
              {!!selectedOrg && selectedOrgCredentials.length > 0 && isStudent && (
                <Grid.Col>
                  <Button
                    icon={'trash'}
                    color={'white'}
                    size={'sm'}
                    className={'text-muted mt-6'}
                    onClick={handleRemoveCredentialClick}
                  >
                    Delete Credentials
                  </Button>
                </Grid.Col>
              )}
            </Grid.Row>
          )}

          {/* MANUALLY ENTERED USPA FORM IS USED WHEN THERE ARE NO AUTH MERITS */}
          {selectedOrg > 0 && (
            <ManualCredentialForm
              orgCredentials={selectedOrgCredentials}
              orgId={selectedOrg}
              studentId={isStudent ? currentUser?.id : Number(user?.id)}
              editable={canEdit}
            />
          )}

          {!selectedOrg && (
            <>
              <Grid.Row className="mt-5 mb-5">
                <Grid.Col sm={6} md={6}>
                  <Form.Group label="USPA Member #">
                    <Form.Input
                      className={'w-50'}
                      readOnly
                      invalid={!memberLicense?.merits_uspa_member_id}
                      value={
                        !memberLicense?.merits_uspa_member_id
                          ? 'Unknown'
                          : memberLicense?.merits_uspa_member_id ?? ''
                      }
                    />
                  </Form.Group>
                </Grid.Col>
                <Grid.Col sm={6} md={6}>
                  <Form.Group label="Expires">
                    {hasLifetimeMembership ? (
                      <Grid.Row className="mb-6">
                        <Grid.Col>
                          <Form.Input
                            valid={true}
                            tick={true}
                            value="Lifetime Member"
                          />
                        </Grid.Col>
                      </Grid.Row>
                    ) : (
                      <Form.Input
                        name="merits_uspa_member_expiration"
                        placeholder="MM/DD/YYYY"
                        className={isMemberLicenseExpired ? 'text-danger' : ''}
                        readOnly
                        invalid={
                          isMemberLicenseExpired ||
                          !memberLicense?.merits_uspa_member_expiration
                        }
                        valid={
                          !isMemberLicenseExpired &&
                          memberLicense?.merits_uspa_member_expiration
                        }
                        value={
                          memberLicense?.merits_uspa_member_expiration
                            ? new Intl.DateTimeFormat().format(
                                new Date(
                                  memberLicense.merits_uspa_member_expiration
                                )
                              )
                            : ''
                        }
                        cross={isMemberLicenseExpired}
                        tick={
                          !isMemberLicenseExpired &&
                          memberLicense?.merits_uspa_member_expiration
                        }
                      />
                    )}
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
            </>
          )}

          {coachLicense && (
            <Grid.Row>
              <Grid.Col sm={6} md={6}>
                <Form.Group>
                  <Form.Input
                    readOnly
                    name="organization_coach_number"
                    value={coachLicense.template_title}
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col sm={6} md={6}>
                <Form.Group>
                  <Form.Input
                    name="organization_coach_expire"
                    placeholder="00/00/0000"
                    className={isCoachLicenseExpired ? 'text-danger' : ''}
                    readOnly
                    invalid={isCoachLicenseExpired}
                    valid={!isCoachLicenseExpired}
                    value={
                      coachLicense?.merits_uspa_member_expiration
                        ? new Intl.DateTimeFormat().format(
                            new Date(coachLicense.merits_uspa_member_expiration)
                          )
                        : ''
                    }
                    cross={isCoachLicenseExpired}
                    tick={!isCoachLicenseExpired}
                  />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>
          )}

          {otherMeritsWithExpiration.length > 0 &&
            otherMeritsWithExpiration.map((merit) => (
              <Grid.Row key={merit.template_id}>
                <Grid.Col sm={6} md={6}>
                  <Form.Group>
                    <Form.Input readOnly value={merit.template_title} />
                  </Form.Group>
                </Grid.Col>
                <Grid.Col sm={6} md={6}>
                  <Form.Group>
                    <Form.Input
                      name="organization_coach_expire"
                      placeholder="00/00/0000"
                      className={
                        merit.merits_uspa_member_expiration &&
                        new Date(merit.merits_uspa_member_expiration) <
                          new Date()
                          ? 'text-danger'
                          : ''
                      }
                      readOnly
                      invalid={
                        merit.merits_uspa_member_expiration &&
                        new Date(merit.merits_uspa_member_expiration) <
                          new Date()
                      }
                      valid={
                        merit.merits_uspa_member_expiration &&
                        new Date(merit.merits_uspa_member_expiration) >=
                          new Date()
                      }
                      value={
                        merit.merits_uspa_member_expiration
                          ? new Intl.DateTimeFormat().format(
                              new Date(merit.merits_uspa_member_expiration)
                            )
                          : ''
                      }
                      cross={
                        merit.merits_uspa_member_expiration &&
                        new Date(merit.merits_uspa_member_expiration) <
                          new Date()
                      }
                      tick={
                        merit.merits_uspa_member_expiration &&
                        new Date(merit.merits_uspa_member_expiration) >=
                          new Date()
                      }
                    />
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
            ))}

          {!selectedOrg && merits.length !== 0 && (
            <>
              <Grid.Row className="mt-5">
                <Grid.Col sm={6} md={6}>
                  <Form.Group label="License" className="mb-0" />
                </Grid.Col>
                <Grid.Col sm={6} md={6}>
                  <Form.Group label="License Number" className="mb-0" />
                </Grid.Col>
              </Grid.Row>
              {sortedABCDLicenses.length > 0 &&
                sortedABCDLicenses.map((licenseType) => (
                  <Grid.Row key={licenseType.template_id}>
                    <Grid.Col sm={6} md={6} className="grid-col col-xs-6">
                      <Form.Group>
                        <Form.Input
                          readOnly
                          value={licenseType.template_title}
                        />
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col sm={6} md={6} className="grid-col col-xs-6">
                      <Form.Group>
                        <Form.Input
                          readOnly
                          value={licenseType.merits_uspa_license}
                        />
                      </Form.Group>
                    </Grid.Col>
                  </Grid.Row>
                ))}
              {sortedOtherLicenses.length > 0 && (
                <Grid.Row className="mt-5">
                  {sortedOtherLicenses
                    .filter((license) => isUSPAOrg(license) || isAdmin)
                    .map((licenseType) => (
                      <Grid.Col
                        sm={6}
                        md={6}
                        className="py-2 grid-col col-xs-12 mb-0"
                        key={licenseType.template_id}
                      >
                        <Icon name="award" className="text-success" />{' '}
                        {licenseType.template_title}
                      </Grid.Col>
                    ))}
                </Grid.Row>
              )}
            </>
          )}
        </Dimmer>
      </Card.Body>
      {memberUpdated && selectedOrg <= 1 && (
        <Card.Footer>
          <Grid.Row>
            <Grid.Col xs={12} sm={12} md={12} lg={3}>
              <i className="fe fe-check-circle text-cyan mr-2" />
              Synced with {''}
              <span>
                <a
                  className='text-cyan'
                  target="_blank"
                  href="https://www.merits.com"
                  rel="noopener noreferrer"
                >
                  Merit
                </a>
              </span>
            </Grid.Col>
            <Grid.Col xs={12} sm={12} md={12} lg={9} className="text-right">
              {false && merits.length === 0 && (
                <Text.Small className="text-danger">
                  <strong>Error Syncing Merits:</strong> We have successfully
                  synced this user's Merits account, however we cannot obtain
                  credentials. This error generally occurs when the user's{' '}
                  <strong>USPA Account Email</strong> and{' '}
                  <strong>Merit Account Email</strong> do not match.
                  <a href="/help"> Need Help?</a>
                </Text.Small>
              )}
              <>
                <span>{`Updated - ${memberUpdated}`}</span>
                <span className="d-block small">
                  {`${memberName}`}
                  {merits[0].first_name !== user?.first_name &&
                    merits[0].last_name !== user?.last_name && (
                      <i
                        title="Merit name does not match account name!"
                        className="fe fe-alert-triangle text-warning ml-1 mr-1"
                      />
                    )}
                  {` - ${memberEmail}`}
                  {memberEmail !== user?.email && (
                    <i
                      title="Merit email does not match account email!"
                      className="fe fe-alert-triangle text-warning ml-1"
                    />
                  )}
                </span>
              </>
            </Grid.Col>
          </Grid.Row>
        </Card.Footer>
      )}
    </>
  )
}

export default UserCredentialsForm
