import React, { useMemo } from 'react'
import { useGetCampStudents } from '../../user/registrations/hooks/useGetRegisteredStudents'
import useGetRegistrationStatus from '../../team-registration-status/hooks/useGetRegistrationStatus'
import { Card, Dimmer, Grid, Icon } from 'tabler-react'

const ParticipantsList = ({ campId, teamId }) => {
  const { data, loading } = useGetCampStudents({ campId })
  const { teamStatusOptions } = useGetRegistrationStatus(Number(teamId))
  const confirmedStatus = useMemo(() => {
    return (
      teamStatusOptions.find(
        (status) => status.type === 'Confirmed' || status.name === 'Confirmed'
      ) ?? null
    )
  }, [teamStatusOptions])

  const students = useMemo(() => {
    return (
      data.filter((student) => student.status === confirmedStatus?.name) ?? []
    )
  }, [data, loading, confirmedStatus])
  return (
    <Dimmer active={loading} loader={loading}>
      <Card>
        <Card.Header>
          <Card.Title>
            <Icon name="check-circle" className="mr-2 ml-0 text-success" />
            Confirmed
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <Grid.Row>
            {students.map((student) => (
              <Grid.Col sm={6} lg={4} key={student.id}>
                {student.first_name} {student.last_name}
              </Grid.Col>
            ))}
          </Grid.Row>
        </Card.Body>
      </Card>
    </Dimmer>
  )
}

export default ParticipantsList
