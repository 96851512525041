import { EHostnames } from 'components/constants'
import { EUserTypes } from 'components/User/userTypes'
import { useRootStore } from 'hooks/useRootStore'
import React from 'react'
import { Link } from 'react-router-dom'
import { Container, Grid, List, Text } from 'tabler-react'

const FooterNav = () => {
  const rootStore = useRootStore()
  const { domainData, currentUser } = rootStore

  const userTypeLinks = {
    [EUserTypes.events]: '/events/pricing',
    [EUserTypes.dzm]: '/dzm/pricing',
    [EUserTypes.rigger]: '/rigger/pricing',
    [EUserTypes.coach]: '/coach/pricing',
  }

  return (
    <div className="footer">
      <Container>
        <Grid.Row>
          <Grid.Col lg={8} sm={12} xs={12} className="grid-col mb-sm-4">
            <Grid.Row>
              {userTypeLinks[currentUser?.type] && (
                <Grid.Col className="grid-col" lg={4} sm={12} xs={12}>
                  <List unstyled={true} className="mb-0">
                    <li>
                      <Link to={userTypeLinks[currentUser?.type]}>Upgrade Plan</Link>
                    </li>
                  </List>
                </Grid.Col>
              )}
            </Grid.Row>
          </Grid.Col>
          <Grid.Col lg={4} sm={12} xs={12} className="grid-col text-right">
            {domainData?.key === EHostnames.FAIRFIGHTS || domainData?.key === EHostnames.COMBAT
              ? 'Combat Sports Digital Credentials Platform'
              : 'Skydiving Community Platform'}
          </Grid.Col>
        </Grid.Row>
      </Container>
    </div>
  )
}

export default FooterNav
