import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import React, { useContext, useMemo } from 'react'
import { toast } from 'react-toastify'
import { Avatar, Button, Card, Dropdown, Grid, Icon, Text } from 'tabler-react'
import useReactRouter from 'use-react-router'
import { UserStoreContext } from '../../contexts/userStoreContext'
import { DELETE_STUDENT_FROM_GROUP } from '../../graphql/DELETE_STUDENT_FROM_GROUP'
import { GET_PAYMENT_FEE_BY_TEAM_ID } from '../../graphql/GET_PAYMENT_FEE_BY_TEAM_ID'
import { GET_STRIPE_PUBLIC_KEY } from '../../graphql/GET_STRIPE_PUBLIC_KEY'
import { GET_STUDENT_BY_ID } from '../../graphql/GET_STUDENT_BY_ID'
import { GET_PAYMENT_FEE_BY_TEAM_ID as GET_PAYMENT_FEE_BY_TEAM_ID_TYPE } from '../../graphql/types/GET_PAYMENT_FEE_BY_TEAM_ID'
import { GET_STUDENT_BY_ID as GET_STUDENT_BY_ID_TYPE } from '../../graphql/types/GET_STUDENT_BY_ID'
import useGetCurrentUserType from '../../modules/common/hooks/useGetCurrentUserType'
import GroupPaymentModal from '../../modules/user/registrations/components/modals/GroupPaymentModal'
import { Types } from '../../types/graphql'
import { formatMoney } from '../../utils/numberFormat'
import { Loading } from '../Loading'
import Modal from '../Modal'
import AddStudentToRegistrationGroupModal from './Modals/AddStudentToRegistrationGroupModal'
import EditRegistrationGroupMemberModal from './Modals/EditRegistrationGroupMemberModal'

interface RegistrationGroupItemProps {
  group: Types.Group
  campId?: number
  isGroupManager?: boolean
  groupClickHandle: (group) => void
  currency: string
  paymentDescription?: string
  paymentEmail?: string
  isRegistrationGroups?: boolean
  paypal?: boolean
  venmo?: boolean
}

const RegistrationGroupItem = ({
  group,
  campId,
  isGroupManager,
  groupClickHandle,
  currency,
  paymentDescription,
  paymentEmail,
  isRegistrationGroups,
  paypal,
  venmo
}: RegistrationGroupItemProps) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const { isAdmin, isCoachSuperAdmin } = useGetCurrentUserType()
  const [groupPaymentModalOpen, setGroupPaymentModalOpen] =
    React.useState(false)
  const { loading: loading1, data: stripeData } = useQuery(
    GET_STRIPE_PUBLIC_KEY
  )
  const stripeKey = useMemo(
    () => stripeData?.getStripePublicKey,

    [stripeData?.getStripePublicKey]
  )

  const { loading: loading2, data: paymentData } =
    useQuery<GET_PAYMENT_FEE_BY_TEAM_ID_TYPE>(GET_PAYMENT_FEE_BY_TEAM_ID, {
      variables: {
        teamId: Number(group.team_id)
      }
    })

  if (loading1 || loading2) {
    return <Loading />
  }
  const paymentFee =
    paymentData?.getPaymentFeeByTeamId.payment_fee_customer / 100

  return (
    <>
      <Card.Footer>
        <Grid.Row>
          <Grid.Col className="mt-1 pr-0" width="12">
            <Text className={'font-weight-bold float-right text-muted'}>
              {formatMoney(group.paymentDetails.total / 100, currency)}
            </Text>
            <h4 className={'mb-0'}>
              <span
                className={'cursor-pointer'}
                onClick={() => groupClickHandle(group)}
              >
                {group.name}
              </span>
            </h4>
            <Text.Small
              className={'text-muted cursor-pointer'}
              onClick={() => groupClickHandle(group)}
            >
              {group.discipline}
            </Text.Small>
          </Grid.Col>
          <Grid.Col>
            <AddStudentToRegistrationGroupModal
              campId={campId}
              group={group}
              isOpen={isOpen}
              toggle={() => setIsOpen(false)}
            />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row className="mb-3">
          <Grid.Col className={'ml-4 mt-4'}>
            {group?.members.length > 0 &&
              group?.members.map((member) => (
                <RegistrationGroupItemMember
                  key={member.id}
                  member={member}
                  group={group}
                  campId={campId}
                  isGroupManager={isGroupManager}
                />
              ))}
          </Grid.Col>
        </Grid.Row>
        {isGroupManager ? (
          <Button
            block
            icon="user-plus"
            color="white"
            size="sm"
            className="mt-4"
            onClick={() => setIsOpen(true)}
          >
            Group Member
          </Button>
        ) : (
          <Text.Small className="text-muted">
            Each group member is required to register. For larger teams, you can request a competitor import <a href="https://skycru.com/competitor-import" target='_blank'>here</a>.
          </Text.Small>
        )}
      </Card.Footer>
      {isRegistrationGroups && (
        <Card.Footer>
          {isAdmin && (
           <Grid.Row>
              <Grid.Col>
                <Text className={'font-weight-bold float-right text-muted'}>
                  {formatMoney(group.paymentDetails.total / 100, currency)}
                </Text>
                <Text className="font-weight-bold">Group Total:</Text>
              </Grid.Col>
            </Grid.Row>
          )}
          <Grid.Row>
            <Grid.Col>
              <Text
                className={
                  'font-weight-bold float-right ' +
                  (group.paymentDetails.totalPaid === 0 ? '' : 'text-success')
                }
              >
                {formatMoney(group.paymentDetails.totalPaid / 100, currency)}
              </Text>
              <Text className="font-weight-bold">Paid:</Text>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col>
              <Text
                className={
                  'font-weight-bold float-right ' +
                  (group.paymentDetails.outstanding === 0
                    ? ''
                    : 'text-danger cursor-pointer')
                }
                onClick={() => {
                  if (isAdmin || isCoachSuperAdmin) {
                    setGroupPaymentModalOpen(true)
                  }
                }}
              >
                {formatMoney(group.paymentDetails.outstanding / 100, currency)}
              </Text>
              <Text className="font-weight-bold">Balance:</Text>
            </Grid.Col>
          </Grid.Row>
        </Card.Footer>
      )}
      {group.paymentDetails.outstanding &&
      group.paymentDetails.outstanding > 0 ? (
        <Card.Footer>
          <Button
            icon="credit-card"
            color="success"
            className="float-right"
            onClick={() => setGroupPaymentModalOpen(true)}
          >
            Payment
          </Button>
        </Card.Footer>
      ) : null}
      {stripeKey && (
        <Elements stripe={loadStripe(stripeKey)}>
          <GroupPaymentModal
            amount={group.paymentDetails.outstanding / 100}
            currency={currency}
            isModalOpen={groupPaymentModalOpen}
            name={`${group.name}`}
            paymentFee={paymentFee}
            toggleModal={() => setGroupPaymentModalOpen(!groupPaymentModalOpen)}
            group={group}
            paymentDescription={paymentDescription}
            paymentEmail={paymentEmail}
            paypal={paypal}
            venmo={venmo}
          />
        </Elements>
      )}
    </>
  )
}

export default RegistrationGroupItem

const RegistrationGroupItemMember = ({
  member,
  group,
  campId,
  isGroupManager
}) => {
  const userStore = useContext(UserStoreContext)
  const { history } = useReactRouter()
  const { user } = userStore
  const [getStudentData] = useLazyQuery<GET_STUDENT_BY_ID_TYPE>(
    GET_STUDENT_BY_ID,
    {
      onCompleted(data) {
        if (data) {
          userStore.detach(user)
          userStore.loadUser(data.getStudentById)
          history.push('/user-details')
        }
      }
    }
  )
  const [isOpen, setIsOpen] = React.useState(false)
  const [isRemoveOpen, setIsRemoveOpen] = React.useState(false)
  const alternateRouteToStudent = () => {
    getStudentData({
      variables: {
        student_id: member.id
      }
    })
  }

  const dropdownOptions = [
    {
      icon: 'user',
      value: 'VIEW',
      onClick: () => {
        alternateRouteToStudent()
      }
    },
    {
      icon: 'edit',
      value: 'EDIT',
      onClick: () => setIsOpen(true)
    },

    {
      icon: 'x-circle',
      value: 'REMOVE',
      onClick: () => setIsRemoveOpen(true)
    }
  ].filter((option) => option)
  return (
    <div className="cursor-pointer">
      <Grid.Row>
        <Grid.Col className="ml-0 pl-0" width={12}>
          {isGroupManager && (
            <Dropdown
              className={' float-right cursor-pointer ' + (group.color && '')}
              toggle={false}
              arrow
              triggerContent={<Icon name="more-vertical" />}
              itemsObject={dropdownOptions}
            />
          )}
          <Avatar
            size="md"
            className="float-left mr-5 cursor-pointer"
            imageURL={
              (member.profile_avatar &&
                `/api/s3/uploads/${member.profile_avatar}`) ||
              '//www.gravatar.com/avatar?d=mp'
            }
          />
          <Text>
            <b>{`${member.first_name} ${member.last_name}`}</b>
          </Text>
          <Text>{member.roleName && `${member.roleName}`}</Text>
        </Grid.Col>
      </Grid.Row>
      <EditRegistrationGroupMemberModal
        member={member}
        isOpen={isOpen}
        toggle={() => setIsOpen(!isOpen)}
        groupId={group.id}
        campId={campId}
      />
      <ConfirmMemberRemovalModal
        isOpen={isRemoveOpen}
        toggle={() => setIsRemoveOpen(!isRemoveOpen)}
        member={member}
        group={group}
      />
    </div>
  )
}

const ConfirmMemberRemovalModal = ({ isOpen, toggle, member, group }) => {
  const [removeGroupMember] = useMutation(DELETE_STUDENT_FROM_GROUP, {
    onCompleted: () => {
      toast.success('Group member removed')
      toggle()
    },
    refetchQueries: ['GET_GROUPS','GET_REGISTRATION_GROUPS']
  })
  return (
    <Modal
      open={isOpen}
      onClose={toggle}
      title="Remove Member"
      content={
        <Grid.Row>
          <Grid.Col>
            <Text>
              Are you sure you want to remove{' '}
              <strong>{member.first_name + ' ' + member.last_name}</strong> from{' '}
              <strong>{group.name}</strong>?
            </Text>
          </Grid.Col>
        </Grid.Row>
      }
      actions={
        <Grid.Row>
          <Grid.Col>
            <Button color="white" onClick={toggle}>
              CANCEL
            </Button>
            <Button
              color="primary"
              className="ml-2"
              onClick={() => {
                removeGroupMember({
                  variables: {
                    groupId: group.id,
                    studentId: member.id
                  }
                })
              }}
            >
              CONFIRM
            </Button>
          </Grid.Col>
        </Grid.Row>
      }
    />
  )
}
