import React from 'react';
import { Grid, Page, PricingCard } from 'tabler-react';
import Wrapper from 'pages/layout';

const EventsPricingPage = (props) => {
  return (
    <Wrapper {...props} title="Plans & Pricing">
      <Page.Content title="Event Organizer Plans & Pricing">
        <Grid.Row>
          <Grid.Col xs={12} sm={12} lg={3}>
            <PricingCard>
              <PricingCard.Category>{'EVENTS BASIC'}</PricingCard.Category>
              <PricingCard.Price>{'$0'} </PricingCard.Price>
              <PricingCard.AttributeList>
                <PricingCard.AttributeItem>
                  <strong>1 </strong>
                  {'Organizer Account'}
                </PricingCard.AttributeItem>
                <PricingCard.AttributeItem hasIcon available>
                  {'Score Cards'}
                </PricingCard.AttributeItem>
                <PricingCard.AttributeItem hasIcon available>
                  {'Product Management'}
                </PricingCard.AttributeItem>
                <PricingCard.AttributeItem hasIcon available>
                  {'Event Management'}
                </PricingCard.AttributeItem>
                <PricingCard.AttributeItem hasIcon available>
                  {'Unlimited User Accounts'}
                </PricingCard.AttributeItem>
                <PricingCard.AttributeItem hasIcon available>
                  {'Payments/Credit Cards'}
                </PricingCard.AttributeItem>
              </PricingCard.AttributeList>
              <PricingCard.Button> {'Always FREE'} </PricingCard.Button>
            </PricingCard>
          </Grid.Col>

          <Grid.Col xs={12} sm={12} lg={3}>
            <PricingCard active>
              <PricingCard.Category>{'EVENTS PLUS'}</PricingCard.Category>
              <PricingCard.Price>{'$10'} </PricingCard.Price>
              <PricingCard.AttributeList>
                <PricingCard.AttributeItem>
                  <strong>5 </strong>
                  {'Organizer Accounts'}
                </PricingCard.AttributeItem>
                <PricingCard.AttributeItem hasIcon available>
                  {'Everything in ORGANIZER BASIC'}
                </PricingCard.AttributeItem>
                <PricingCard.AttributeItem hasIcon available>
                  {'Advanced Registration'}
                </PricingCard.AttributeItem>
                <PricingCard.AttributeItem hasIcon available>
                  {'Advanced Functionality'}
                </PricingCard.AttributeItem>
                <PricingCard.AttributeItem hasIcon available>
                  {'Full Activity Logs'}
                </PricingCard.AttributeItem>
                <PricingCard.AttributeItem hasIcon available>
                  {'Reduced Onboarding Fees'}
                </PricingCard.AttributeItem>
              </PricingCard.AttributeList>
              <PricingCard.Button
                onClick={() => window.open("https://buy.stripe.com/fZe03Lb9g0Vs5gc3cf", '_blank')}
                active>{'BUY NOW'} </PricingCard.Button>
            </PricingCard>
          </Grid.Col>

          <Grid.Col xs={12} sm={12} lg={3}>
            <PricingCard>
              <PricingCard.Category>{'EVENTS PREMIUM'}</PricingCard.Category>
              <PricingCard.Price>{'$50'} </PricingCard.Price>
              <PricingCard.AttributeList>
                <PricingCard.AttributeItem>
                  <strong>50 </strong>
                  {'Organizer Accounts'}
                </PricingCard.AttributeItem>
                <PricingCard.AttributeItem hasIcon available>
                  {'Onboarding Fees Included'}
                </PricingCard.AttributeItem>
                <PricingCard.AttributeItem hasIcon available>
                  {'Self Checkin'}
                </PricingCard.AttributeItem>
                <PricingCard.AttributeItem hasIcon available>
                  {'Recurring Transactions'}
                </PricingCard.AttributeItem>
                <PricingCard.AttributeItem hasIcon available>
                  {'Account Transfers'}
                </PricingCard.AttributeItem>
                <PricingCard.AttributeItem hasIcon available>
                  {'Premium Functionality'}
                </PricingCard.AttributeItem>
              </PricingCard.AttributeList>
              <PricingCard.Button
                onClick={() => window.open("https://buy.stripe.com/8wMg2J6T033AfUQfZ7", '_blank')}>{'BUY NOW'} </PricingCard.Button>
            </PricingCard>
          </Grid.Col>

          <Grid.Col xs={12} sm={12} lg={3}>
            <PricingCard>
              <PricingCard.Category>{'COACH UNLIMITED'}</PricingCard.Category>
              <PricingCard.Price>{'$100'} </PricingCard.Price>
              <PricingCard.AttributeList>
                <PricingCard.AttributeItem>
                  <strong>Unlimited </strong>
                  {'Organizer Accounts'}
                </PricingCard.AttributeItem>
                <PricingCard.AttributeItem hasIcon available>
                  {'Open Scheduling'}
                </PricingCard.AttributeItem>
                <PricingCard.AttributeItem hasIcon available>
                  {'Custom Development/Features'}
                </PricingCard.AttributeItem>
                <PricingCard.AttributeItem hasIcon available>
                  {'Custom Reports'}
                </PricingCard.AttributeItem>
                <PricingCard.AttributeItem hasIcon available>
                  {'SMS Functionality'}
                </PricingCard.AttributeItem>
              </PricingCard.AttributeList>
              <PricingCard.Button>{'Contact Sales'} </PricingCard.Button>
            </PricingCard>
          </Grid.Col>
        </Grid.Row>
      </Page.Content>
    </Wrapper>
  );
};

export default EventsPricingPage;
