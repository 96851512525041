import React, { useContext } from 'react'
import { Types } from '../../../../types/graphql'
import OrgCredentialMembershipCard from '../../../../components/OrgCredentials/OrgCredentialMembershipCard'
import { UserStoreContext } from '../../../../contexts/userStoreContext'
import { Alert, Card, Grid, Header, List, Text } from 'tabler-react'
import moment from 'moment/moment'

const UserMeritsAndCredentials = ({ params }) => {
  const { user } = useContext(UserStoreContext)

  const {
    memberLicense,
    isMemberLicenseExpired,
    sortedABCDLicenses,
    memberUpdated,
    memberEmail,
    memberName,
    hasLifetimeMembership,
    merits,
    orgs,
    studentManualCredentials
  } = params
  return (
    <>
      {!merits.length &&
        (!studentManualCredentials || studentManualCredentials.length <= 0) && (
          <Card.Body>
            <Alert type="info" className="text-center">
              <strong>No Credentials</strong>
            </Alert>
          </Card.Body>
        )}
      {studentManualCredentials && (
        <Card.Body>
          <Grid.Row>
            {orgs.map((org: Types.TeamOrg) => {
              const membership: Types.OrgCredential =
                studentManualCredentials.find(
                  (credential: Types.OrgCredential) =>
                    credential.org_id === org.org_id && credential.membership
                )
              const licenses: Types.OrgCredential[] =
                studentManualCredentials.filter(
                  (credential: Types.OrgCredential) =>
                    credential.org_id === org.org_id && !credential.membership
                )

              return (
                <>
                  {!org.org_id && memberLicense ? (
                    <Grid.Col sm={12} md={6} lg={6} key={org.org_id}>
                      <Card>
                        <Card.Body>
                          <Header.H5>
                          {org.slug.toUpperCase() + ': '}
                            <strong>
                              {memberLicense?.merits_uspa_member_id}{' '}
                              {hasLifetimeMembership ? ' Lifetime Member' : ''}
                            </strong>
                          </Header.H5>
                          {!hasLifetimeMembership && (
                            <Text className={'mb-3'}>
                              Expires:{' '}
                              <span
                                className={`${
                                  isMemberLicenseExpired
                                    ? 'text-danger'
                                    : 'text-success'
                                }`}
                              >
                                {moment(memberLicense?.merits_uspa_member_expiration).locale('en').format('MMM D, YYYY')}
                              </span>
                            </Text>
                          )}
                          {sortedABCDLicenses.length > 0 && (
                            <span className="mt-3">
                              <List unstyled>
                                {sortedABCDLicenses.map((license) => (
                                  <List.Item key={license.id}>
                                    <i className="fe fe-award text-primary mr-2" />
                                    {license.merits_uspa_license}{' '}
                                    {license?.merits_uspa_member_expiration && (
                                      <span
                                        className={`${
                                          moment(
                                            license.merits_uspa_member_expiration
                                          ).isBefore(moment())
                                            ? 'text-danger'
                                            : 'text-success'
                                        }`}
                                      >
                                        {license.merits_uspa_member_expiration}
                                      </span>
                                    )}
                                  </List.Item>
                                ))}
                              </List>
                            </span>
                          )}
                        </Card.Body>
                        {/* 
                        <Card.Footer>
                          <Text.Small className="text-muted"><i className="fe fe-check-circle text-cyan mr-1" />Synced with <a href="https://www.merits.com/" target='_blank' className='text-cyan'>Merit</a></Text.Small>
                        </Card.Footer>
                        */}
                      </Card>
                    </Grid.Col>
                  ) : (
                    <>
                      {org.org_id && membership && (
                        <Grid.Col sm={12} md={6} lg={6} key={org.org_id}>
                          <OrgCredentialMembershipCard
                            membershipCredential={membership}
                            org={org}
                            licenses={licenses}
                          />
                        </Grid.Col>
                      )}
                    </>
                  )}
                </>
              )
            })}
          </Grid.Row>
        </Card.Body>
      )}
      {memberUpdated && (
        <Card.Footer>
          <Grid.Row>
            <Grid.Col xs={12} sm={12} md={12} lg={3}>
              <i className="fe fe-check-circle text-cyan mr-2" />
              Synced with {''}
              <span>
                <a
                  className='text-cyan'
                  target="_blank"
                  href="https://www.merits.com"
                  rel="noopener noreferrer"
                >
                  Merit
                </a>
              </span>
            </Grid.Col>
            <Grid.Col xs={12} sm={12} md={12} lg={9} className="text-right">
              {false && merits.length === 0 && (
                <Text.Small className="text-danger">
                  <strong>Error Syncing Merits:</strong> We have successfully
                  synced this user's Merits account, however we cannot obtain
                  credentials. This error generally occurs when the user's{' '}
                  <strong>USPA Account Email</strong> and{' '}
                  <strong>Merit Account Email</strong> do not match.
                  <a href="/help"> Need Help?</a>
                </Text.Small>
              )}
              <span>{`Updated - ${memberUpdated}`}</span>
              <span className="d-block small">
                {`${memberName}`}
                {merits[0].first_name !== user?.first_name &&
                  merits[0].last_name !== user?.last_name && (
                    <i
                      title="Merit name does not match account name!"
                      className="fe fe-alert-triangle text-warning ml-1 mr-1"
                    />
                  )}
                {` - ${memberEmail}`}
                {memberEmail !== user?.email && (
                  <i
                    title="Merit email does not match account email!"
                    className="fe fe-alert-triangle text-warning ml-1"
                  />
                )}
              </span>
            </Grid.Col>
          </Grid.Row>
        </Card.Footer>
      )}
    </>
  )
}

export default UserMeritsAndCredentials
