import { useQuery } from '@apollo/client'
import axios from 'axios'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Button, Header } from 'tabler-react'
import useGetCurrentUserType from '../../modules/common/hooks/useGetCurrentUserType'
import { useRootStore } from '../../hooks'
import { EUserTypes } from '../User/userTypes'
import { Loading } from 'components/Loading'
import { GET_ORG_CREDENTIALS } from '../../graphql/GET_ORG_CREDENTIALS'
import { useGetMeritsByStudentId } from '../../modules/user/hooks/merits'

interface IStudentMeritsButtonProps {
  studentId: string
}


const StudentMeritsButton = ({ studentId }: IStudentMeritsButtonProps) => {
  const {memberLicense, hasLifetimeMembership, sortedABCDLicenses, loading} = useGetMeritsByStudentId(studentId)

  const { data: studentManualCredentials, loading: manualCredentialsLoading } =
    useQuery(GET_ORG_CREDENTIALS, {
      variables: { studentId }
    })

  const hasManualUSPA= useMemo(() => {
    return studentManualCredentials?.orgCredentials?.some(
      (item) => Number(item.org_id) === 1 && item.membership
    );
  }, [studentManualCredentials, manualCredentialsLoading])

  if (loading || manualCredentialsLoading) {
    return <Loading />
  }

  return <StudentMeritsButtonInner memberLicense={memberLicense} hasLifetimeMembership={hasLifetimeMembership} sortedABCDLicenses={sortedABCDLicenses} hasManualUSPA={hasManualUSPA}/>
}

const StudentMeritsButtonInner = ({ memberLicense, hasLifetimeMembership, sortedABCDLicenses, hasManualUSPA }) => {
  const { currentUser } = useRootStore();
  const { isStudent } = useGetCurrentUserType()

  const isMemberLicenseExpired = useMemo(
    () =>
      memberLicense?.merits_uspa_member_expiration &&
      new Date(memberLicense.merits_uspa_member_expiration) < new Date(),
    [memberLicense]
  );

  const isLicenseExpired = useMemo(() => {
    if (hasLifetimeMembership) {
      return false;
    }

    const meritLicense = sortedABCDLicenses?.length
      ? sortedABCDLicenses[sortedABCDLicenses.length - 1]
      : null

    if (meritLicense?.merits_uspa_member_expiration > Date.now()) {
      return false
    }

    if (
      new Date(meritLicense?.merits_uspa_member_expiration).getTime() >
      Date.now()
    ) {
      return false
    }

    if (!isMemberLicenseExpired) {
      return false;
    }

    return true;
  }, [

    sortedABCDLicenses,
    isMemberLicenseExpired,
    hasLifetimeMembership
  ]);

  //MERITS
  const [meritConfig, setMeritConfig] = useState(null);

  const queryServer = async (url: string) => {
    try {
      const response = await axios.post(url);

      setMeritConfig(response.data);
    } catch (err: any) {
      console.log(err.message);
    }
  };

  const current = useCallback(() => {
    queryServer('/auth/merit/current');

  }, []);

  useEffect(() => {
    current();
  }, [current]);

  const [submitted, setSubmitted] = useState(false);

  // Reload on screen focus
  useEffect(() => {
    const handleWindowFocus = () => {
      // Call your function here
      if (submitted) {
        current();
      }
    };

    document.addEventListener('focus', handleWindowFocus);

    // Cleanup function to remove the event listener
    return () => {
      document.removeEventListener('focus', handleWindowFocus);
    };
  }, [submitted]);

  const MeritsSyncButton = () => {
    const location = useLocation();
    const url = `/auth/merit/sync?redirect=${location.pathname}`

    return (
      <>
          <a href={url} className="d-block">
          <Button
            block
            icon="refresh-cw"
            color="secondary"
            className="d-block"
            disabled={!isStudent}
          >
            USPA (Merits)
          </Button>
          </a>
      </>
    )
  }

  return (
    <>
      {/* If the user has user_merits, display the highest licence number and expiraton status */}
      {sortedABCDLicenses?.length > 0 ? (
      <Header.H5 className="mt-3">
        <span className={`status-icon ${isLicenseExpired ? 'bg-danger' : 'bg-success'}`} />
        {' USPA '}
        {sortedABCDLicenses.at(-1)?.merits_uspa_license || sortedABCDLicenses.at(-1)?.template_title || ''}
      </Header.H5>
      ) : null}
      {/* If user is a student AND they have USPA org_credentials AND either membership is expired or but no user_merits, display SYNC button */}
      {currentUser.type === EUserTypes.student && hasManualUSPA && (isLicenseExpired || !sortedABCDLicenses?.length) && (
        <MeritsSyncButton />
      )}
    </>
  );
};

export default StudentMeritsButton;
