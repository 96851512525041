import React from 'react'
import { formatMoney } from '../../../utils/numberFormat'
import moment from 'moment/moment'
import { Table, Tag } from 'tabler-react'
import { Types } from '../../../types/graphql'

type GroupPaymentHistoryProps = {
  group: Types.Group
  payment: Types.Payments
}

const GroupPaymentItem = ({ group, payment }: GroupPaymentHistoryProps) => {
  return (
    <Table.Row key={payment.id}>
      <Table.Col>{group.name}</Table.Col>
      <Table.Col>
        {formatMoney(payment.amount, payment.currency)}
        {payment.fees > 0
          ? ' + ' + formatMoney(payment.fees, payment.currency) + ' fees'
          : null}
      </Table.Col>
      <Table.Col>{payment.manual_payment}{payment.card_brand}</Table.Col>
      <Table.Col>
        {payment?.coach_first_name
          ? `${payment?.coach_first_name} ${payment?.coach_last_name}`
          : `${payment?.student_first_name ?? ''} ${payment?.student_last_name ?? ''}`}
      </Table.Col>
      <Table.Col>{moment(payment.created_on).format('ll')}</Table.Col>
      <Table.Col>
        <Tag color={payment.status === 'Paid' ? 'success' : ''}>
          {payment.status}
        </Tag>
      </Table.Col>
    </Table.Row>
  )
}

export default GroupPaymentItem
