import React from 'react'
import { Link } from 'react-router-dom'
import { Grid, StampCard } from 'tabler-react'
import formatNumber, { formatMoney } from 'utils/numberFormat'
import useGetCurrentUserType from '../../../modules/common/hooks/useGetCurrentUserType'

type PaymentListHeaderProps = {
  total: number | null
  totalComp: number | null
  totalPaid: number | null
  totalAppFee: number | null
  totalCustomerFee: number | null
  appFeeCount: number | null
}

const PaymentListHeader = ({
  total,
  totalComp,
  totalPaid,
  totalAppFee,
  totalCustomerFee
}: PaymentListHeaderProps) => {
  const { isAdmin } = useGetCurrentUserType()

  return (
    <>
      <Grid.Col sm={6} lg={3} xs={12} className="">
        <StampCard
          color="success"
          icon="dollar-sign"
          header={
            <Link to={`/payments`}>
              {formatNumber(total)} <small>Payments</small>
            </Link>
          }
          footer={`${formatMoney(totalPaid)} PAID`}
        />
      </Grid.Col>
      {isAdmin && (
        <>
          <Grid.Col sm={6} lg={3} xs={12} className="">
            <StampCard
              color="primary"
              icon="dollar-sign"
              header={
                <Link to={`#`}>
                  {formatNumber(0)} <small>Credit Cards</small>
                </Link>
              }
              footer={`${formatMoney(0)}`}
            />
          </Grid.Col>
          <Grid.Col sm={6} lg={3} xs={12} className="">
            <StampCard
              color="info"
              icon="dollar-sign"
              header={
                <Link to={`#`}>
                  {formatNumber(0)} <small>Manual Payments</small>
                </Link>
              }
              footer={`${formatMoney(0)}`}
            />
          </Grid.Col>
          <Grid.Col sm={6} lg={3} xs={12} className="">
            <StampCard
              color="warning"
              icon="dollar-sign"
              header={
                <Link to={`#`}>
                  {formatNumber(0)} <small>App Fees</small>
                </Link>
              }
              footer={`${formatMoney(totalAppFee)} | ${formatMoney(
                totalCustomerFee
              )}`}
            />
          </Grid.Col>
        </>
      )}
    </>
  )
}

export default PaymentListHeader
