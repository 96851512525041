import React from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { EUserTypes } from 'components/User/userTypes';
import { useRootStore } from '../hooks/useRootStore';
import PageNoAccess from '../pages/PageNoAccess';

interface IPrivateRouteProps {
  component: any;
  exact: boolean;
  path: string;
  roles?: string[];
}

const PrivateRoute = ({
  component: Component,
  roles,
  ...rest
}: IPrivateRouteProps) => {
  const rootStore = useRootStore();
  const { isAuthenticated, currentUser } = rootStore;
  const location = useLocation();

  if (!isAuthenticated) {
    rootStore.setRedirect(location.pathname);
    return <Redirect to="/login" />;
  }

  if (roles?.indexOf(currentUser.type) === -1) {
      return <PageNoAccess subtitle="Unauthorized Access" />;
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        return <Component {...props} />;
      }}
    />
  );
};

export default PrivateRoute;
