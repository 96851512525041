import React from 'react';
import { Dropdown, Nav } from 'tabler-react';
import { Link } from 'react-router-dom';
import StudentSearchBars from '../../components/Students/StudentSearchBars';
import useGetNodeEnvironment, {
  ENodeEnvironment
} from 'modules/common/hooks/useGetNodeEnvironment'
import { getEnvironmentFromHostname } from '../../utils/misc'

const nodeEnv = useGetNodeEnvironment()
const isProduction = nodeEnv === ENodeEnvironment.PRODUCTION
const environment = getEnvironmentFromHostname(
  window.location.hostname ?? 'unknown'
)

const StudentNav = () => {
  return (
    <>
      <Nav.Item icon="home" to="/" value="/" />
      <Nav.Item icon="book" to="/user/accounts" value="Accounts" />
      {/*!isProduction && (
        <>
          <Nav.Item icon="shopping-bag" to="#" value="Marketplace" />
          <Nav.Item icon="users" to="#" value="MyCRU" />
        </>
      )*/}
      <Nav.Item icon="briefcase" to="/user/gear" value="MyGEAR" />
      <li className="nav-item ml-lg-auto mb-4 mb-lg-0 d-print-none">
        <Dropdown
          toggle={false}
          icon="calendar"
          position="bottom-end"
          flex
          className="mr-4 dropdown-header-icon-lg"
          items={
            <>
              <Link to="/user/calendar" className="dropdown-item d-none d-md-block">
                Calendar
              </Link>
              <Link to="/my-events" className="dropdown-item">
                My Events
              </Link>
            </>
          }
        />
        <StudentSearchBars />
      </li>
    </>
  );
};

export default StudentNav;
